import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import dateFormat from 'dateformat';
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"

// Images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.jpg"
import blogImg2 from "../../../images/home/blog-img-02.jpg"
import blogImg3 from "../../../images/home/blog-img-03.jpg"
import blogImg4 from "../../../images/home/blog-img-04.jpg"
import ContactCard from "../../ContactCard/ContactCard"
import "./FilterBlock.scss"

import { OtherInsights } from "../../../queries/common_use_query"
import { removeDataAttributes } from "../../../comQueryStructure";

const FilterBlock = props => {

  //const {loading, error, data} = OtherInsights(props.insights_id, props.Filter_by_Tag);
  const { loading, error, data } = OtherInsights(props.Filter_by_Tag);

  const otherInsightData = removeDataAttributes(data?.insights)

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: otherInsightData && otherInsightData && otherInsightData.length > 3 ? 4 : otherInsightData && otherInsightData && otherInsightData.length,
    slidesToScroll: 1,
    infinite: otherInsightData && otherInsightData && otherInsightData.length > 3 ? true : false,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: otherInsightData && otherInsightData && otherInsightData.length > 2 ? 3 : otherInsightData && otherInsightData && otherInsightData.length,
          slidesToScroll: 1,
          infinite: otherInsightData && otherInsightData && otherInsightData.length > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <>
      {otherInsightData && otherInsightData.length > 0 &&
        <div className={`filter-block other_insights ${otherInsightData && otherInsightData.length < 4 ? 'other_sml_wdth' : ''}`}>
          <Container>
            <Row>
              <Col lg="12">
                <div className="filter-block-title">
                  <div className="animated">
                    <h2>Other insights that may interest you</h2>
                  </div>
                </div>


                <div className="filter-block-list text-center text-xl-left">
                  <Slider {...settings}>
                    {
                      otherInsightData && otherInsightData.length > 0 && otherInsightData.map((item, index) => {
                        var date_of_item = item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                        var split_tags = item.Tag ? item.Tag.split(', ') : '';
                        return (
                          <div className="animated">
                            <ContactCard
                              cardImg={item.Tile_Image}
                              cardTitle={item.Title}
                              date={date_of_item}
                              cardTag={item.Tag ? split_tags[0] : item.Category.replace('_', ' ')}
                              link_url={item.URL}
                              externalLink={item.External_Link}
                              imagename={"insight.Tile_Image.tile_image"}
                              article_id={item.id}
                              imagetransforms={item.imagetransforms}
                            />
                          </div>
                        )
                      })
                    }
                  </Slider>


                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  )
}

export default FilterBlock
